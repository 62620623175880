import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, CircularProgress } from '@mui/material';
import './ApplicationForm.css'; // Import your custom CSS for styling

const ApplicationForm = () => {

  const { jobId } = useParams();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('');
  const [resume, setResume] = useState(null);
  const [location, setLocation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [agreeToPolicy, setAgreeToPolicy] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const API_BASE_URL = process.env.REACT_APP_API;

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleResumeChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    if (file && allowedTypes.includes(file.type)) {
      setResume(file);
      setErrorMessages((prev) => ({ ...prev, resume: '' }));
    } else {
      setErrorMessages((prev) => ({ ...prev, resume: 'Only .doc and .pdf files are allowed.' }));
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!firstName) errors.firstName = 'First name is required.';
    if (!lastName) errors.lastName = 'Last name is required.';
    if (!validateEmail(emailAddress)) errors.emailAddress = 'Invalid email format.';
    if (!yearsOfExperience) errors.yearsOfExperience = 'Years of experience is required.';
    if (!resume) errors.resume = 'Resume is required.';
    if (!location) errors.location = 'Location is required.';
    if (!agreeToPolicy) errors.agreeToPolicy = 'You must agree to the contact policy to proceed.';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setErrorMessages(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append('JobID', jobId);
    formData.append('FirstName', firstName);
    formData.append('LastName', lastName);
    formData.append('EmailAddress', emailAddress);
    formData.append('Phone', phone);
    formData.append('YearsOfExperience', yearsOfExperience);
    formData.append('Resume', resume);
    formData.append('Location', location);

    try {
      await axios.post(
        `${API_BASE_URL}api/hr/jobs/${jobId}/apply/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      navigate('/application-success-page'); // Redirect to success page after successful application
    } catch (error) {
      console.error('Error applying for job:', error);
      if (error.response && error.response.data) {
        setErrorMessages((prev) => ({ ...prev, submit: error.response.data.detail || 'Failed to apply for job. Please try again.' }));
      } else {
        setErrorMessages((prev) => ({ ...prev, submit: 'Failed to apply for job. Please try again.' }));
      }
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <div className="application-form-container">
      {isLoading && (
        <div className="loader-overlay">
          <CircularProgress size={80} />
          <p>Submitting your application...</p>
        </div>
      )}
      <h2>Job Application Form</h2>
      <form onSubmit={handleSubmit} className="Application-form-form" disabled={isLoading}>
        <TextField
          id="firstName"
          label="First Name"
          variant="outlined"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            setErrorMessages((prev) => ({ ...prev, firstName: e.target.value ? '' : 'First name is required.' }));
          }}
          placeholder="Enter your first name"
          fullWidth
          required
        />
        {errorMessages.firstName && <p className="error-message">{errorMessages.firstName}</p>}

        <TextField
          id="lastName"
          label="Last Name"
          variant="outlined"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
            setErrorMessages((prev) => ({ ...prev, lastName: e.target.value ? '' : 'Last name is required.' }));
          }}
          placeholder="Enter your last name"
          fullWidth
          required
        />
        {errorMessages.lastName && <p className="error-message">{errorMessages.lastName}</p>}

        <TextField
          id="emailAddress"
          label="Email Address"
          variant="outlined"
          value={emailAddress}
          onChange={(e) => {
            setEmailAddress(e.target.value);
            setErrorMessages((prev) => ({ ...prev, emailAddress: validateEmail(e.target.value) ? '' : 'Invalid email format.' }));
          }}
          placeholder="e.g. name@example.com"
          fullWidth
          required
        />
        {errorMessages.emailAddress && <p className="error-message">{errorMessages.emailAddress}</p>}

        <TextField
          id="phone"
          label="Phone"
          variant="outlined"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
            // setErrorMessages((prev) => ({ ...prev, phone: validatePhone(e.target.value) ? '' : 'Phone number should be 10 digits.' }));
          }}
          placeholder="Enter your phone number"
          fullWidth
          required
        />
        {errorMessages.phone && <p className="error-message">{errorMessages.phone}</p>}

        <TextField
          id="yearsOfExperience"
          label="Years of Experience"
          variant="outlined"
          type="number"
          value={yearsOfExperience}
          onChange={(e) => {
            setYearsOfExperience(e.target.value);
            setErrorMessages((prev) => ({ ...prev, yearsOfExperience: e.target.value ? '' : 'Years of experience is required.' }));
          }}
          placeholder="e.g. 2.5"
          fullWidth
          required
        />
        {errorMessages.yearsOfExperience && <p className="error-message">{errorMessages.yearsOfExperience}</p>}
        <input
          type="file"
          id="resume"
          onChange={handleResumeChange}
          required
        />
        {errorMessages.resume && <p className="error-message">{errorMessages.resume}</p>}

        <TextField
          id="location"
          label="Location"
          variant="outlined"
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
            setErrorMessages((prev) => ({ ...prev, location: e.target.value ? '' : 'Location is required.' }));
          }}
          placeholder="Enter your location"
          fullWidth
          required
        />
        {errorMessages.location && <p className="error-message">{errorMessages.location}</p>}

        <div className="contact-policy">
          <input
            type="checkbox"
            id="contactPolicyCheckbox"
            className="contact-policy-checkbox"
            checked={agreeToPolicy}
            onChange={() => {
              setAgreeToPolicy(!agreeToPolicy);
            }}
          />
          <label htmlFor="contactPolicyCheckbox" className="contact-policy-label">
            Do you agree to our <a href="/privacy" target="_blank" className="contact-policy-link">Contact Policy?*</a>
          </label>
        </div>
        {errorMessages.agreeToPolicy && <p className="error-message">{errorMessages.agreeToPolicy}</p>}

        <button type="submit" disabled={isLoading}>Submit Application</button>
        {errorMessages.submit && <p className="error-message">{errorMessages.submit}</p>}
      </form>
    </div>
  );
};

export default ApplicationForm;
