import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ApplicationList.css';

const ApplicationList = () => {
  const { jobId } = useParams();
  const [applications, setApplications] = useState([]);
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API;

  const fetchJobDetails = useCallback(async () => {
    try {
      const token = localStorage.getItem('accessToken'); // Correct token retrieval
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get(`${API_BASE_URL}api/hr/jobs/${jobId}/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setJobDetails(response.data);
    } catch (error) {
      console.error('Error fetching job details:', error.response ? error.response.data : error.message);
      setError('Failed to fetch job details. Please try again.');
    }
  }, [jobId, API_BASE_URL]);

  const fetchApplications = useCallback(async () => {
    try {
      const token = localStorage.getItem('accessToken'); // Correct token retrieval
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get(`${API_BASE_URL}api/hr/jobs/${jobId}/applications/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setApplications(response.data);
    } catch (error) {
      console.error('Error fetching applications:', error.response ? error.response.data : error.message);
      setError('Failed to fetch applications. Please try again.');
    }
  }, [jobId, API_BASE_URL]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchJobDetails(), fetchApplications()]);
      setLoading(false);
    };
    fetchData();
  }, [fetchJobDetails, fetchApplications]);


  const getResumeUrl = (resumePath) => {
    if (resumePath.startsWith('http')) {
      return resumePath;
    }
    return `${API_BASE_URL}${resumePath.startsWith('/') ? '' : '/'}${resumePath}`;
  };

  const downloadResume = async (resumeUrl, filename) => {
    try {
      const token = localStorage.getItem('accessToken'); // Correct token retrieval
      const response = await axios.get(resumeUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading resume:', error);
      alert(`Failed to download resume: ${error.message}`);
    }
  };

  const handleDelete = async (jobId,ApplicationId) => {
    console.log("Job ID before passing:", jobId);
    console.log("Application ID before passing:", ApplicationId);
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) throw new Error('No token found');
      await axios.delete(`${API_BASE_URL}api/hr/jobs/${jobId}/applications/${ApplicationId}/delete`, {
        headers: { Authorization: `Bearer ${token}` ,
          'Content-Type': 'application/json'
        },
      });
      setApplications((prevApplications) => prevApplications.filter((app) => app.ApplicationID !== ApplicationId));
      alert('Application deleted successfully.');
    } catch (error) {
      console.error('Error deleting application:', error.response ? error.response.data : error.message);
      alert('Failed to delete application. Please try again.');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  };

  if (loading) {
    return <div className="application-list-container">Loading...</div>;
  }

  return (
    <div className="application-list-page">
    <div className="application-list-container">
      <h1 className="application-list-heading">
        {jobDetails ? jobDetails.Title : `Job ${jobId}`}
      </h1>
      {error && <p className="error-message">{error}</p>}

      <p className="applicant-count">{applications.length} applicants</p>

      {applications.length > 0 ? (
        <table className="application-table">
          <thead>
            <tr>
              <th>Applicant Name</th>
              <th>Phone</th>
              <th>Qualification</th>
              <th>Location</th>
              <th>Submitted On</th>
              <th>Status</th>
              <th>Resume</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {applications.map(application => (
              <tr key={application.ApplicationID}>
                <td>{application.FirstName} {application.LastName}</td>
                <td>{application.Phone}</td>
                <td>{application.YearsOfExperience}</td>
                <td>{application.Location}</td>
                <td>{formatDate(application.DateOfSubmission)}</td>
                <td className="status">{application.Status}</td>
                <td>
                  {application.Resume && (
                    <button className="resume-download" onClick={() => {
                      const fileExtension = application.Resume.split('.').pop().toLowerCase();
                      const filename = `resume_${application.FirstName}_${application.LastName}.${fileExtension}`;
                      const resumeUrl = getResumeUrl(application.Resume);
                      downloadResume(resumeUrl, filename);
                    }}>
                      Download
                    </button>
                  )}
                </td>
                <td className='delete-buttons'>
                  <button onClick={() => handleDelete(jobId,application.ApplicationID)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="no-applications-message">No applications submitted yet.</p>
      )}
    </div>
    </div>
  );
};

export default ApplicationList;