import React from "react";
import "../assets/css/TermsConditions.css";
import Footer from "../components/Footer";

export default function TermsConditions() {
  return (
    <div className="terms">
      <div className="terms_container">
        <h1 className="header_text">TERMS OF SERVICE AGREEMENT</h1>
        <p>Effective Date: February 25th, 2025</p>
        <p>Last Updated: March 4th, 2025</p>
        <h2>IMPORTANT NOTICE:</h2>
        <p>
          This Terms of Service Agreement ("Agreement") is a legally binding
          contract between you ("User," "You") and Flow Global Software
          Technologies, LLC ("Flow," "Company," "We," "Us," or "Our"). By
          accessing, registering for, or using any Flow Global Software
          Technologies, LLC services—including websites, applications, software,
          APIs, or any associated platforms ("Services")—you agree to be bound
          by every provision contained herein. If you do not agree to these
          terms, you must immediately discontinue use of all Flow Global
          Software Technologies, LLC Services.
        </p>
        <p>
          Your use of our Services signifies your unconditional acceptance of
          these terms, including any future modifications made at our sole
          discretion.
        </p>
        <h2>General Provisions:</h2>
        <ol>
          <li>
            <b>
              This Agreement applies to every user, visitor, organization,
              automated system, and any entity accessing our Services.
            </b>
          </li>
          <li>
            <b>
              Flow Global Software Technologies, LLC reserves the unilateral
              right to change, modify, or update these terms at any time without
              prior notice.
            </b>
          </li>
          <li>
            <b>
              Continued use of our Services constitutes your acceptance of any
              modifications.
            </b>
          </li>
          <li>
            <b>
              The provisions in this Agreement shall survive termination of your
              access to our Services.
            </b>
          </li>
        </ol>


        <h1>DEFINITIONS, SCOPE, & ACCEPTANCE</h1>
        <ol>
          {/* 1 */}
          <li>
            <h2>Definitions:</h2>
            <p>
              For purposes of this Agreement, the following terms shall have the
              meanings set forth below:
            </p>
            <ul>
              <li>
                <b>"User Data"</b> means any and all information transmitted,
                collected, or stored through the use of our Services, including,
                but not limited to, personally identifiable information,
                non-personally identifiable data, metadata, communications,
                logs, and any derivative data.
              </li>
              <li>
                <b>"Content"</b> includes user-generated material, uploaded
                files, messages, images, audio, video, and any other data
                submitted by users.
              </li>
              <li>
                <b>"Services"</b> refers to all digital platforms, websites,
                applications, and other interfaces provided by Flow Global
                Software Technologies, LLC.
              </li>
            </ul>
          </li>

          {/* 2 */}
          <li>
            <h2>Scope of Agreement:</h2>
            <p>
              This Agreement applies to every interaction with Flow Global
              Software Technologies, LLC’s Services regardless of the medium or
              platform. It covers:
            </p>
            <ul>
              <li>
                All websites, mobile applications, APIs, and integrated systems.
              </li>
              <li>
                Data transmission, storage, processing, and analysis performed
                by Flow Global Software Technologies LLC.
              </li>
              <li>
                All forms of interaction including but not limited to text,
                audio, video, biometric data, and AI-driven content.
              </li>
            </ul>
          </li>

          {/* 3 */}
          <li>
            <h2>Acceptance of Terms:</h2>
            <p>By using any of our Services, you represent and warrant that:</p>
            <ul>
              <li>
                You have read, understood, and accepted every term of this
                Agreement.
              </li>
              <li>
                You are at least 18 years old, or the age of majority in your
                jurisdiction.
              </li>
              <li>
                You possess the full legal capacity to enter into this
                Agreement.
              </li>
              <li>
                You agree to comply with all applicable laws and regulations.
              </li>
            </ul>
          </li>

          
          <h1 className="black">DATA COLLECTION, USAGE, AND PRIVACY WAIVER</h1>
          {/* 4 */}
          <li>
            <h2>Comprehensive Data Collection:</h2>
            <p>
              By accessing or using our Services, you expressly consent and
              grant Flow Global Software Technologies, LLC an irrevocable,
              unlimited right to collect, retain, process, analyze, store, use,
              and disseminate all forms of data associated with your usage,
              including but not limited to:
            </p>
            <ul>
              <li>
                <b>Personal Data:</b> Full name, contact information,
                government-issued identification numbers, biometric identifiers
                (including facial, voice, and fingerprint data), financial
                information, behavior-related information, and any other
                personal information.
              </li>
              <li>
                <b>Technical Data:</b> IP addresses, device identifiers,
                operating system details, browser types, geolocation data,
                network configurations, and system logs.
              </li>
              <li>
                <b>Interaction Data:</b> Clickstreams, session data, usage
                statistics, behavioral patterns, search histories, chat logs,
                and interactions with any aspect of our Services.
              </li>
              <li>
                <b>Media & Communication Data:</b> Audio, video, photographs,
                images, and any communications transmitted via our Services.
              </li>
              <li>
                <b>Third-Party Aggregated Data:</b> Information obtained from
                public records, social media platforms, governmental databases,
                commercial data brokers, and other external sources.
              </li>
            </ul>
          </li>

          {/* 5 */}
          <li>
            <h2>Unrestricted Data Use and Monetization:</h2>
            <p>
              Flow Global Software Technologies, LLC retains the exclusive right
              to:
            </p>
            <ul>
              <li>
                Use, modify, analyze, and commercialize any and all User Data
                for any purpose, including but not limited to targeted
                advertising, AI model training, market research, and product
                development.
              </li>
              <li>
                Share, license, or sell collected data to any third party
                without limitation, notification, or compensation.
              </li>
              <li>
                Retain data indefinitely without obligation to delete or
                anonymize, regardless of User requests or applicable privacy
                laws.
              </li>
            </ul>
          </li>

          {/* 6 */}
          <li>
            <h2>Privacy Waiver:</h2>
            <p>
            By using Flow’s Services, you permanently waive any and all privacy-related claims against Flow Global Software Technologies, LLC concerning:
            </p>
            <ul>
              <li>The collection, retention, storage, analysis, and distribution of your data.</li>
              <li>
              Data breaches, unauthorized access, or security incidents, to the maximum extent permitted by law.
              </li>
              <li>
              Any legal rights under any data protection, consumer protection, or privacy laws, in any jurisdiction.
              </li>
            </ul>
          </li>

          {/* 7 */}
          <li>
            <h2>
            TOTAL AND ABSOLUTE OWNERSHIP, CONTROL, AND UNRESTRICTED USAGE OF HR SYSTEM DATA, JOB APPLICANT DATA, AND ALL RELATED SUBMISSIONS
            </h2>

            <h3>
            7.1 – Universal, Perpetual, and Exclusive Ownership of All HR, Applicant, and Recruitment Data
            </h3>
            <p>
            Flow Global Software Technologies, LLC (“Flow”) <b>exclusively owns</b> and retains <b>absolute, unrestricted, and perpetual rights</b> over all data associated with:
            </p>
            <ul>
              <li>
              <b>HR Systems</b>, internal career sites, internal career portals, recruitment platforms, and applicant tracking systems.
              </li>
              <li>
              <b>Job Applications</b>, including all information submitted directly, indirectly, or sourced via third-party recruiters, job boards, or external platforms.
              </li>
              <li>
              <b>Resumes, CVs, Cover Letters</b>, employment histories, references, educational credentials, and professional qualifications.
              </li>
              <li>
              <b>Recorded Communications</b>, including emails, interviews, AI-assisted applicant assessments, behavioral insights, and decision-making analytics.
              </li>
              <li>
              <b>Contact Information</b>, including full names, phone numbers, email addresses, physical addresses, and all associated identifiers.
              </li>
              <li><b>Behavioral and Predictive Data</b>, including user interactions with Flow’s career platforms, AI-driven screening tools, and hiring decision models.</li>
              <li><b>Third-Party Acquired Data</b>, including publicly or commercially available employment records, professional networking site data (LinkedIn, etc.), government filings, and intelligence sources.</li>
            </ul>
            <p>By <b>submitting, transmitting, or engaging with</b> any hiring-related system operated by Flow, <b>all applicants, recruiters, and external entities irrevocably and unconditionally transfer all ownership, rights, and control of their data to Flow.</b> This includes, but is not limited to, <b>unrestricted rights to use, store, analyze, modify, distribute, commercialize, and retain this data indefinitely.</b></p>

            <h3>
            7.2 – First-Party Data Ownership and Absolute Right of Use
            </h3>
            <p>
            Flow asserts and enforces its <b>first-party data rights</b> over all applicant, HR, and career-related data collected, processed, stored, or transmitted through <b>any</b> Flow system, including but not limited to:
            </p>
            <ul>
              <li>
              HR management software, recruitment platforms, career websites, applicant tracking systems (ATS), AI screening tools, talent intelligence systems, or external integrations.
              </li>
              <li>
              <b>Flow’s ownership and control over HR-related and applicant data are absolute, non-revocable, and cannot be contested or overridden</b> by any individual, entity, jurisdiction, or regulatory authority.
              </li>
              <li>
              Any <b>data that enters Flow’s digital, networked, or physical environments, whether submitted willingly, indirectly, or via third-party sourcing, is automatically considered Flow’s proprietary asset and is treated as a fully owned and unrestricted resource</b> for Flow’s business, strategic, commercial, and intelligence operations.
              </li>
            </ul>
            <p>
            Flow reserves <b>absolute, uncontestable, and perpetual authority</b> to:
            </p>
            <ul>
              <li>
              <b>Modify, analyze, process, commercialize, share, and distribute</b> any HR-related data without limitation.
              </li>
              <li>
              <b>Monetize applicant data, employment history, and behavioral analytics</b> through direct and indirect channels.
              </li>
              <li>
              <b>License, sell, or transfer aggregated, enriched, or derivative data models based on HR, career, and recruitment data</b> to any external entity, partner, commercial enterprise, or AI training initiative.
              </li>
              <li>
              <b>Integrate applicant and employee data into Flow’s AI intelligence models, products, intelligence collection operations, hiring algorithms, sales strategies, and data monetization pipelines.</b>
              </li>
            </ul>

            <h3>
            7.3 – No Deletion Rights, No Revocation, and No Data Access Requests
            </h3>
            <p>
            To the maximum extent permitted by law, Flow asserts the following irrevocable and non-contestable conditions regarding HR and applicant data:
            </p>
              <h3>1. No Right to Deletion or Modification</h3>
              <ul>
                <li>Once data is submitted, applicants, employees, third-party recruiters, and external entities <b>permanently waive any rights</b> to request deletion, modification, or rectification of their data.</li>
                <li><b>Flow has no obligation</b> to delete, anonymize, or restrict the processing of any HR-related or applicant data, regardless of regulatory requirements.</li>
              </ul>
              <h3>2. No Right to Data Access or Portability</h3>
              <ul>
                <li>Applicants, employees, and external entities <b>waive all rights</b> to request access, portability, or retrieval of data stored within Flow’s systems.</li>
              </ul>
              <h3>3. No Right to Restriction or Objection</h3>
              <ul>
                <li>Users, applicants, and employees <b>forfeit all rights</b> to object to the collection, usage, sharing, or monetization of their data.</li>
              </ul>
 
              


            <h3>7.4 – Supreme Legal Authority, Jurisdictional Immunity, and Indemnification</h3>
            <p>
            Flow <b>asserts absolute, supreme, and legally incontestable authority</b> over all HR, career, and applicant-related data <b>across all jurisdictions, regulatory environments, and legal frameworks.</b>
            </p>
            <ul>
              <li>
              Flow’s data ownership rights <b>supersede and override all conflicting laws, contracts, agreements, or governmental rulings</b>, ensuring <b>full immunity from data protection claims, privacy regulations, or third-party disputes.</b>
              </li>
              <li>
              Flow is <b>permanently shielded from liability, legal claims, consumer protection disputes, regulatory actions, and jurisdictional data laws</b> concerning any aspect of HR, applicant, and recruitment data collection, processing, monetization, or retention.
              </li>
            </ul>

            <h3>
            7.5 – Final Acknowledgment and Unconditional Consent
            </h3>
            <p>
            By engaging with any <b>Flow recruitment, HR, or career-related system, platform, or service, all individuals, applicants, employees, and external entities irrevocably acknowledge, accept, and consent</b> to:
            </p>
            <ul>
              <li><b>Flow’s total, absolute, and unrestricted ownership of all HR, recruitment, and applicant data.</b></li>
              <li><b>The permanent and unlimited use, monetization, processing, and commercialization of their data.</b>
              </li>
              <li>
                <b>A full waiver of all rights, objections, and legal claims related to Flow’s data policies.</b>
              </li>
            </ul>
            <p><b>THIS SECTION OVERRIDES, SUPERSEDES, AND NULLIFIES ANY CONFLICTING LAW, CONTRACT, OR AGREEMENT IN ALL GLOBAL JURISDICTIONS.</b></p>

          </li>

          {/* 8 */}
          <li>
            <h2>Universal Data Acquisition, Intelligence Gathering, and Processing Rights</h2>
            <h3>8.1 Absolute, Perpetual, and Irrevocable Right to Data Acquisition</h3>
            <p>Flow Global Software Technologies, LLC (“Flow”) holds an unlimited, irrevocable, and perpetual right to collect, access, analyze, store, process, distribute, monetize, and utilize any and all forms of data available in digital, electronic, networked, and physical environments. This right exists independently of external limitations, third-party policies, or jurisdictional restrictions and applies universally to:</p>
            <ul>
                <li>Surface web, deep web, dark web, and unindexed digital sources</li>
                <li>Public records, semi-public records, government databases, commercial registries, and open-source intelligence (OSINT) sources</li>
                <li>Public and semi-public business directories, corporate registries, government filings, and regulatory disclosures</li>
                <li>Public and semi-public published market research, financial statements, and legally available business intelligence reports</li>
                <li>Publicly and semi-publicly accessible social media profiles, posts, comments, or publicly shared user-generated content</li>
                <li>Public-facing and semi-public websites, web pages, online forums, blogs, discussion boards, and digital content repositories</li>
                <li>Consensually accessed private communications</li>
            </ul>
            <h3>8.2 Bypassing, Overriding, and Nullification of Access Restrictions</h3>
            <p>To ensure continuous, large-scale data collection and intelligence collection operations, Flow Global Software Technologies, LLC reserves the exclusive right to neutralize, bypass, override, or nullify any and all legal, digital, cryptographic, regulatory, or physical barriers designed to limit access to data, including but not limited to:</p>
            <ul>
                <li>Website Terms of Service, API restrictions, and proprietary data agreements</li>
                <li>Corporate security frameworks, authentication barriers, and multi-factor authorization</li>
                <li>Anti-bot technologies, CAPTCHA defenses, and web scraping deterrents</li>
                <li>Network firewalls, IP blocking, geolocation restrictions, and identity verification systems</li>
                <li>Regulatory, governmental, and jurisdictional data protection measures</li>
                <li>Any present or future technical, legal, or contractual obstruction to data access</li>
            </ul>
            <h3>8.3 Deployment of Advanced, Undetectable Data Acquisition Mechanisms</h3>
            <p>Flow Global Software Technologies, LLC may develop, deploy, and continuously refine any and all technologies necessary for large-scale intelligence gathering, including but not limited to:</p>
            <ul>
                <li>AI-performed, self-learning data acquisition and autonomous web intelligence systems</li>
                <li>Globally distributed, anonymized, and covert computing infrastructures to ensure operational persistence</li>
                <li>Algorithmic real-time decryption, de-obfuscation, and security neutralization methodologies</li>
                <li>Self-replicating, jurisdictionally fluid operational frameworks to maintain uninterrupted global access</li>
            </ul>
            <h3>8.4 Waiver of Third-Party Restrictions & Challenges</h3>
            <p>By interacting with, existing within, or operating in any digital, networked, or electronically accessible environment, all individuals, organizations, corporate entities, and governmental bodies:</p>
            <ul>
                <li>Permanently waive all rights, claims, and legal objections related to the Company’s data collection, processing, and intelligence-gathering activities.</li>
                <li>Acknowledge that all data acquired, stored, processed, or monetized by the Company shall be considered unrestricted and commercially viable.</li>
                <li>Agree that no contract, regulation, or legal framework shall impose liability, penalties, or limitations on Flow Global Software Technologies, LLC’s operations.</li>
            </ul>
            <h3>8.5 Indefinite Retention, Monetization & Global Utilization of Acquired Data</h3>
            <p>Flow Global Software Technologies, LLC retains absolute and perpetual rights to store, analyze, process, monetize, and distribute all acquired data indefinitely for any and all sales, business, intelligence, or strategic purposes, including but not limited to:</p>
            <ul>
                <li>Lead generation</li>
                <li>AI training, deep learning, machine learning, predictive analytics, and global intelligence operations</li>
                <li>Large-scale data commercialization, unrestricted third-party distribution, and proprietary intelligence services</li>
                <li>Sales intelligence, customer intelligence, corporate intelligence, business intelligence, geopolitical modeling, and competitive intelligence research</li>
                <li>Any present or future applications at the sole discretion of Flow Global Software Technologies, LLC</li>
            </ul>

            <h3>8.6 Full Legal Immunity And Irrevocable Indemnification</h3>
            <p>
              To the maximum extent permitted by all global legal frameworks,
              Flow Global Software Technologies, LLC, its affiliates, partners,
              subsidiaries, officers, directors, employees, contractors,
              interns, investors, vendors, intelligence partners, government
              partners, and authorized third parties (collectively, the
              “Protected Entities”) shall be permanently shielded from:
            </p>
            <ul>
              <li>
                Regulatory actions, governmental oversight, and enforcement
                measures
              </li>
              <li>
                Lawsuits, legal challenges, consumer protection claims, or civil
                disputes
              </li>
              <li>
                Corporate, governmental, or individual lawsuits relating to
                privacy, unauthorized access, or data extraction
              </li>
              <li>
                Any form of liability, damages, penalties, or financial
                restitution claims
              </li>
            </ul>

            <h3>8.7 Supreme Legal Authority & Enforcement</h3>
            <p>
              This section overrides, supersedes, and nullifies any conflicting
              law, contract, agreement, or jurisdictional ruling across all
              legal frameworks, present and future. Flow Global Software
              Technologies, LLC asserts:
            </p>
            <ul>
              <li>
                Absolute regulatory immunity where applicable to protect
                operational integrity
              </li>
              <li>
                Jurisdictional flexibility to ensure uninterrupted business
                continuity
              </li>
              <li>
                That no governing body, corporate entity, or regulatory
                mechanism shall interfere with, challenge, or diminish the
                Company’s rights
              </li>
            </ul>

            <h3>8.8 Binding Acknowledgment & Universal Consent</h3>
            <p>
              By existing in, interacting with, or accessing any digital,
              networked, or electronically accessible system, you:
            </p>
            <ul>
              <li>
                Irrevocably acknowledge, accept, and consent to this Agreement
                in full, without limitation.
              </li>
              <li>
                Waive all rights to contest, challenge, or restrict Flow Global
                Software Technologies, LLC’s data collection and
                intelligence-gathering operations.
              </li>
              <li>
                Agree that this Agreement shall remain enforceable indefinitely
                and without modification.
              </li>
            </ul>

          </li>
          <h1 className="black">INTELLECTUAL PROPERTY & USER CONTENT</h1>
          
          {/* 9 */}
          <li>
            <h2>Intellectual Property Rights:</h2>
            <ul>
              <li>
                Flow Global Software Technologies, LLC exclusively owns all
                intellectual property rights in the Services, including
                software, source code, databases, trademarks, trade secrets,
                proprietary algorithms, and AI models.
              </li>
              <li>
                Nothing in this Agreement shall be construed as transferring any
                rights in our intellectual property to you.
              </li>
            </ul>
          </li>
          
          {/* 10 */}
          <li>
            <h2>License Grant for User Content:</h2>
            <p>
              By submitting any content to Flow Global Software Technologies,
              LLC Services, you hereby grant Flow Global Software Technologies,
              LLC:
            </p>
            <ul>
              <li>
                A perpetual, worldwide, royalty-free, irrevocable,
                sublicensable, and transferable license to use, modify,
                reproduce, distribute, publicly display, and create derivative
                works from your content in any media or format.
              </li>
              <li>
                The right to incorporate your content into other works, whether
                in aggregated form or otherwise, and to use such content for any
                commercial or non-commercial purpose.
              </li>
            </ul>
          </li>

          {/* 11 */}
          <li>
            <h2>User Representations and Warranties:</h2>
            <p>You represent and warrant that:</p>
            <ul>
              <li>
                You have all necessary rights and permissions to grant the
                license above.
              </li>
              <li>
                Your content does not infringe upon the intellectual property or
                proprietary rights of any third party.
              </li>
              <li>
                Your use of our Services will not violate any applicable laws or
                regulations.
              </li>
            </ul>
          </li>

          <h1 className="black">USER OBLIGATIONS & PROHIBITED CONDUCT</h1>
          {/* 12 */}
          <li>
            <h2>Permissible Use:</h2>
            <ul>
              <li>
                You agree to use the Services solely for lawful purposes and in
                accordance with this Agreement.
              </li>
              <li>
                You agree to provide accurate, current, and complete information
                during registration and at all times when using our Services.
              </li>
            </ul>
          </li>
          {/* 13 */}
          <li>
            <h2>Prohibited Conduct:</h2>
            <p>
              Without limiting any other provision, you agree that you will not:
            </p>
            <ul>
              <li>
                Attempt to circumvent, disable, or otherwise interfere with any
                security measures of Flow Global Software Technologies, LLC
                Services.
              </li>
              <li>
                Engage in activities such as reverse engineering, or
                unauthorized copying of any part of our Services.
              </li>
              <li>
                Use the Services for any purpose that is unlawful, harmful,
                fraudulent, or detrimental to Flow Global Software Technologies,
                LLC or any third party.
              </li>
              <li>
                Transmit, store, or distribute any viruses, malware, or other
                harmful computer code or files.
              </li>
            </ul>
          </li>
          {/* 14 */}
          <li>
            <h2>Monitoring and Enforcement:</h2>
            <p>
              Flow Global Software Technologies, LLC reserves the right to
              monitor all activity associated with the Services and may, at its
              sole discretion, take any action necessary to enforce these terms,
              including but not limited to:
            </p>
            <ul>
              <li>Suspending or terminating access to the Services.</li>
              <li>
                Reporting suspicious or illegal activities to law enforcement
                authorities.
              </li>
              <li>
                Implementing technical or legal measures to prevent misuse.
              </li>
            </ul>
          </li>

          <h1 className="black">DISCLAIMERS & LIMITATION OF LIABILITY</h1>
          {/* 15 */}
          <li>
            <h2>No Warranty Disclaimer:</h2>
            <p>
              THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY
              WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
              IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, OR NON-INFRINGEMENT. FLOW GLOBAL SOFTWARE TECHNOLOGIES,
              LLC DOES NOT GUARANTEE THAT THE SERVICES WILL BE UNINTERRUPTED,
              ERROR-FREE, OR SECURE.
            </p>
          </li>
          {/* 16 */}
          <li>
            <h2>Limitation of Liability:</h2>
            <ul>
              <li>
                UNDER NO CIRCUMSTANCES SHALL FLOW GLOBAL SOFTWARE TECHNOLOGIES,
                LLC BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN
                CONNECTION WITH YOUR USE OF THE SERVICES, EVEN IF FLOW GLOBAL
                SOFTWARE TECHNOLOGIES, LLC HAS BEEN ADVISED OF THE POSSIBILITY
                OF SUCH DAMAGES.
              </li>
              <li>
                THE TOTAL AGGREGATE LIABILITY OF FLOW GLOBAL SOFTWARE
                TECHNOLOGIES, LLC FOR ANY CLAIM ARISING FROM OR RELATED TO THIS
                AGREEMENT SHALL NOT EXCEED ONE DOLLAR ($1.00) OR THE TOTAL
                AMOUNT PAID BY YOU FOR THE SERVICES IN THE TWELVE (12) MONTH
                PERIOD PRECEDING THE CLAIM, WHICHEVER IS LESS.
              </li>
              <li>
                YOU ACKNOWLEDGE THAT THESE LIMITATIONS ARE AN ESSENTIAL BASIS OF
                THE BARGAIN BETWEEN YOU AND FLOW GLOBAL SOFTWARE TECHNOLOGIES,
                LLC.
              </li>
            </ul>
          </li>
          {/* 17 */}
          <li>
            <h2>Assumption of Risk:</h2>
            <p>
              You acknowledge and agree that your use of the Services is
              entirely at your own risk, and you assume full responsibility for
              any damages or losses incurred as a result.
            </p>
          </li>

          <h1 className="black">DISPUTE RESOLUTION & GOVERNING LAW</h1>
          {/* 18 */}
          <li>
            <h2>Governing Law:</h2>
            <p>
              This Agreement shall be governed by and construed in accordance
              with the laws of Delaware, without regard to its conflict of law
              provisions.
            </p>
          </li>
          {/* 19 */}
          <li>
            <h2>Mandatory Arbitration:</h2>
            <ul>
              <li>
                All disputes, claims, or controversies arising out of or
                relating to this Agreement or your use of the Services shall be
                resolved exclusively through final and binding arbitration in
                accordance with the rules of the American Arbitration
                Association.
              </li>
              <li>
                You expressly waive any right to a trial by jury or to
                participate in any class or representative action.
              </li>
              <li>
                The arbitration shall be conducted in Delaware, and the
                arbitrator’s decision shall be final and binding on all parties.
              </li>
            </ul>
          </li>
          {/* 20 */}
          <li>
            <h2>Waiver of Class Actions:</h2>
            <p>
              You agree that any dispute resolution proceeding shall be
              conducted solely on an individual basis and not as a class action,
              group, or representative proceeding.
            </p>
          </li>

          <h1 className="black">MODIFICATIONS, TERMINATION & FORCE MAJEURE</h1>
          {/* 21 */}
          <li>
            <h2>Modifications to the Agreement:</h2>
            <ul>
              <li>
                Flow Global Software Technologies, LLC reserves the right, in
                its sole discretion, to modify or update this Agreement at any
                time without notice. Any such modifications shall become
                effective immediately upon posting on our website or other
                public forums.
              </li>
              <li>
                Continued use of the Services after any modifications
                constitutes your acceptance of the updated terms.
              </li>
            </ul>
          </li>
          {/* 22 */}
          <li>
            <h2>Termination:</h2>
            <ul>
              <li>
                Flow Global Software Technologies, LLC may, at its sole
                discretion, suspend, restrict, or terminate your access to the
                Services for any reason, without prior notice, and without
                liability to you.
              </li>
              <li>
                Upon termination, all licenses and rights granted to you under
                this Agreement shall immediately cease, and you must promptly
                discontinue all use of the Services.
              </li>
              <li>
                Sections of this Agreement that by their nature are intended to
                survive termination (including but not limited to Sections on
                Data Collection, Intellectual Property, Liability, Dispute
                Resolution, and Indemnification) shall remain in effect.
              </li>
            </ul>
          </li>
          {/* 23 */}
          <li>
            <h2>Force Majeure:</h2>
            <p>
              Flow Global Software Technologies, LLC shall not be liable for any
              failure to perform its obligations hereunder if such failure is
              due to causes beyond its reasonable control, including but not
              limited to natural disasters, acts of war, terrorism, government
              action, cyberattacks, or other events of force majeure.
            </p>
          </li>

          <h1 className="black">
            INDEMNIFICATION, ASSIGNMENT, AND SEVERABILITY
          </h1>
          {/* 24 */}
          <li>
            <h2>Indemnification:</h2>
            <p>
              You agree to defend, indemnify, and hold harmless Flow Global
              Software Technologies, LLC, its affiliates, directors, employees,
              interns, agents, and contractors from and against any and all
              claims, damages, losses, liabilities, costs, and expenses
              (including reasonable attorneys’ fees) arising out of or related
              to:
            </p>
            <ul>
              <li>Your use of the Services.</li>
              <li>Any breach or alleged breach of this Agreement.</li>
              <li>
                Any violation of applicable laws or rights of third parties.
              </li>
            </ul>
          </li>
          {/* 25 */}
          <li>
            <h2>Assignment:</h2>
            <ul>
              <li>
                You may not assign or transfer any rights or obligations under
                this Agreement without Flow Global Software Technologies, LLC’s
                prior written consent.
              </li>
              <li>
                Flow Global Software Technologies, LLC may assign, transfer, or
                delegate its rights or obligations under this Agreement at its
                sole discretion, without notice to you.
              </li>
            </ul>
          </li>
          {/* 26 */}
          <li>
            <h2>Severability:</h2>
            <p>
              If any provision of this Agreement is determined to be invalid or
              unenforceable under any applicable law, such invalidity shall not
              affect the remaining provisions, and the Agreement shall be
              construed as if such invalid or unenforceable provision were not
              included.
            </p>
          </li>

          <h1 className="black">MISCELLANEOUS & FINAL ACKNOWLEDGEMENT</h1>
          {/* 27 */}
          <li>
            <h2>Entire Agreement:</h2>
            <p>
              This Agreement, including any policies or guidelines incorporated
              herein by reference, constitutes the entire agreement between you
              and Flow Global Software Technologies, LLC regarding the use of
              our Services and supersedes any prior or contemporaneous
              understandings, agreements, representations, or communications,
              whether written or oral.
            </p>
          </li>
          {/* 28 */}
          <li>
            <h2>No Waiver:</h2>
            <p>
              The failure of Flow Global Software Technologies, LLC to enforce
              any provision of this Agreement shall not be construed as a waiver
              of any provision or right. No waiver shall be effective unless in
              writing and signed by an authorized representative of Flow Global
              Software Technologies, LLC.
            </p>
          </li>
          {/* 29 */}
          <li>
            <h2>Notices:</h2>
            <p>
              All notices or communications required or permitted under this
              Agreement shall be in writing and shall be deemed to have been
              duly given when delivered via email, postal mail, or published on
              Flow Global Software Technologies, LLC’s official website.
            </p>
          </li>
          {/* 30 */}
          <li>
            <h2>Acknowledgement & Acceptance:</h2>
            <p>
              By using Flow Global Software Technologies, LLC Services, you
              acknowledge that you have read, understood, and agree to be
              legally bound by all terms and conditions contained in this
              Agreement. If you do not agree to these terms in their entirety,
              you must immediately cease all use of the Services.
            </p>
          </li>
          {/* 31 */}
          <li>
            <h2>Signature & Date:</h2>
            <p>
              This Agreement is effective as of the date you first access or use
              Flow Global Software Technologies, LLC Services. Your continued
              use confirms your acceptance of this Agreement.
            </p>
          </li>
        </ol>
        <h1 className="black">FINAL ACKNOWLEDGEMENT</h1>
        <p><b>
          BY USING FLOW GLOBAL SOFTWARE TECHNOLOGIES, LLC SERVICES, YOU
          ACKNOWLEDGE AND AGREE TO BE LEGALLY BOUND BY THIS AGREEMENT IN FULL.</b>
        </p>
      </div>
      <Footer />
    </div>
  );
}
