import React from "react";
import { Link } from "react-router-dom";

import TwitterX from "../assets/img/icon/TwitterX.png";
import Linkedin from "../assets/img/icon/Linkedin.png";
import logo_white from "../assets/img/icon/logo_white.png";

import "../assets/css/Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="links">
        <div>
          <div className="footer-row">
            <ul>
              <li>
                <Link to="/">
                  <img className="footer-flow-logo" src={logo_white} alt="" />
                </Link>
              </li>
            </ul>

            <div className="mobile-social-links">
              <div>
                <Link to="https://www.linkedin.com/company/flow-global-software-technologies/">
                  <img className="footer-social-logo" src={Linkedin} alt="" />
                </Link>
              </div>
              <div>
                <Link to="https://x.com/FlowAICompany">
                  <img className="footer-social-logo" src={TwitterX} alt="" />
                </Link>
              </div>
            </div>

            <ul>
              <li>
                <Link to="/">
                  <h4 className="footer-heading">Home</h4>
                </Link>
              </li>
              <li>
                <Link to="/product">Product</Link>
              </li>
              <li>
                <Link to="/Pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/use-cases">Use Cases</Link>
              </li>
              <li>
                <Link to="/customer-service">Customer Service</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/aboutus">
                  <h4 className="footer-heading">About Us</h4>
                </Link>
              </li>
              <li>
                <Link to="/careers">Career</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/resource">
                  <h4 className="footer-heading">Resource</h4>
                </Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              {/* The following has been commented because the pages have not been built yet */}
              {/* <li><Link to="/">My Account</Link></li> */}
              {/* <li><Link to="/">FAQ</Link></li> */}
            </ul>
            <ul>
              <li>
                <Link to="/customer-service">
                  <h4 className="footer-heading">Customer Support</h4>
                </Link>
              </li>
              <li>
                <Link to="/contact">Schedule Free Strategy Session</Link>
              </li>
              <li>
                <Link to="/contact/sales-support">Sales Support</Link>
              </li>
              <li>
                <Link to="/contact/sales-support">Tech Support</Link>
              </li>
              <li>
                <Link to="/contact/sales-support">Billing Support</Link>
              </li>
            </ul>

            <div className="footer-social-logos">
              <div className="m-1">
                <Link to="https://www.linkedin.com/company/flow-global-software-technologies/">
                  <img className="footer-social-logo" src={Linkedin} alt="" />
                </Link>
              </div>
              <div className="m-1">
                <Link to="https://x.com/FlowAICompany">
                  <img className="footer-social-logo" src={TwitterX} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="divider"></div>
      <div className="justify-content-center flex">
        <div className="footer-small-text">
          <div>
            <Link className="text-decoration-none text-white" to="/privacy">
              Privacy Policy{" "}
            </Link>
            |
            <Link className="text-decoration-none text-white" to="/terms">
              {" "}
              Terms of Service
            </Link>
          </div>
          <div className="footer-copyright">
            © 2024 Flow Global Software Technologies, LLC
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
